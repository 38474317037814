<template>
  <div>
    <p>{{ $t('onboarding.generalInformation.desc') }}</p>
    <!-- CHECKBOX -->
    <v-form
      v-model="isFormValid"
    >
      <v-card
        color=element
      >
        <v-container>
          <v-checkbox
            v-model="data.generalInformation.customerType"
            :true-value="customerType.true"
            :false-value="customerType.false"
            :rules="rules.required"
            :label="$t('onboarding.generalInformation.customerType')"
            color="primary"
          ></v-checkbox>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import { required } from '@/utils/validators';

export default {
  name: 'GeneralInformation',
  data() {
    return {
      data: clone(this.$store.state.user.data),
      customerType: {
        true: 1,
        false: null,
      },
      rules: {
        required,
      },
    };
  },
  computed: {
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
    ...mapGetters('onboarding', [
      'editMode',
    ]),
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
    ]),
    async submit() {
      const input = {
        investor: {
          personal: {
            generalInformation: {
              customerType: this.data.generalInformation.customerType,
            },
          },
        },
        mainStage: 'personal',
        subStage: 'generalInformation',
      };
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (this.editMode) {
          this.$store.dispatch('onboarding/editMode', null);
          this.$router.push({
            name: 'Onboarding.Personal',
            params: {
              step: 'Overview',
            },
          });
        } else {
          this.setSubStage('contactdata');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
  },
};
</script>
